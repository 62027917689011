/* Logo formatting */

.Logo {
  margin-top: 2%;
  margin-left: 2%;
}

.Main-logo {
  height: 40px;
}

/* Main class wrapper formatting */

.Main {
  margin: auto;
  align-items: center;
  justify-content: center;
  padding-left: 35%;
  padding-right: 35%;
  padding-bottom: 30%;
  padding-top: 10%;
}

.label {
  font-family:Helvetica-Neue,garamond,serif;font-size:16px;font-style:italic;
}

/* Dynamic input formatting */

.form__group {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  padding: 18px 0 0;
}

.form__field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #63aef4;
  outline: 0;
  font-size: 1.3rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}
.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  white-space: nowrap;
  color: #64ace2;
}
.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #1ba3d5, #24c0f9);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #64ace2;
  font-weight: 700;
}
/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}

/* Static input formatting */

.button__group {
  margin-left: 36%;
  margin-right: 36%;
  display: flex;
  flex-direction: column;
  padding: 18px 0 0;
}

.button {
  color: white;
  font-weight: 300;
  border-radius: 30px;
  font-size: 1.25rem;
  background-color: #028fed;
  border: transparent;
  padding: 12px 5px 12px 5px;
  min-width: 120px;
}

.button:hover {
  background-color: #1998ec;
}

.button:focus {
  background-color: #1193e9;
}

/* Modal formatting */